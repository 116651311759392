<template>
  <div>
    <div style="overflow-x: scroll" v-if="force">
      <div>
        <div style="overflow-x: scroll; display: flex; width: 1000px;">
          <div class="status" v-for="(status) in $store.state.tasks.task_statuses" :key="status.id"
               style="height: 100%; border-right: 1px solid #eaeaea; width: 250px;">
            <div class="status-title"
                 style="border-bottom: 1px solid #eaeaea; width: 100%; padding: 10px;">
              {{ status.name }}

              <v-badge v-if="drag_tasks[status.id]" dark inline :value="parseInt(drag_tasks[status.id].length)"
                       :content="parseInt(drag_tasks[status.id].length)"/>
              <div @click="taskCreateDialog = true; openTaskCreateDialog();" class="float-right add-task">
                <v-icon>mdi-plus</v-icon>
              </div>
            </div>
            <div style="height: 500px; overflow-y: scroll; padding: 10px; " class="hide-scroll">
              <div v-if="drag_tasks[status.id] < 1" class="text-center text-caption">
                Нет задач, можно <br> cюда перетянуть
              </div>
              <draggable    ghost-class="ghost" :list="drag_tasks[status.id]" :move="onMoveCallback" :data-status-id="status.id" group="tasks"
                         style="min-height: 500px;" @end="onEnd">
                <Task :opened="task.id == preSelectedTaskID" :context="context" v-for="task in drag_tasks[status.id]"
                      :key="task.id"
                      :data-task-id="task.id" :task="task"
                      class="mt-2" @click.native="taskDetailsDialog = true; selectedTaskId = task.id"/>
              </draggable>

            </div>
          </div>


        </div>
        <v-card-text>

        </v-card-text>
      </div>
      <TaskCreateDialog :dialog="taskCreateDialog" context="project" @close="taskCreateDialog = false; taskGen()"/>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import Task from "@/components/Tasks/Task.vue";
import TaskCreateDialog from "@/components/Tasks/TaskCreateDialog.vue";
import analytics from "@/api/analytics.js";

export default {
  name: "Board",
  components: {draggable, Task, TaskCreateDialog},
  props: {
    tasks: {
      default: []
    },
    can_create: {},
    taskGen: {},
    context: {},
    preSelectedTaskID: {}
  },
  watch: {
    tasks() {
      this.buildArraysForStatus();
    }
  },
  data() {
    return {
      force: true,
      tasks_arrays: [],
      taskCreateDialog: false,
      selectedTaskId: null,
      drag_tasks: []
    }
  },
  methods: {
    openTaskCreateDialog() {
      analytics.openCreateTaskPage(this.$store.state.user.currentUser.id);
    },
    onMoveCallback(evt) {
      let task_id = parseInt(evt.dragged.getAttribute('data-task-id'));
      let task = this.$store.getters.getTaskByID(task_id);
      return task ? !task.readonly : false;
    },

    buildArraysForStatus() {
      let drag_tasks = [];
      this.$store.state.tasks.task_statuses.forEach((status) => {
        drag_tasks[status.id] = this.$store.state.tasks.tasks.filter(t => parseInt(t.task_statuses_id) === parseInt(status.id));
      });
      this.drag_tasks = [...drag_tasks];
    },

    onEnd(e) {
      let to_status = parseInt(e.to.getAttribute('data-status-id'));
      let task_id = parseInt(e.item.getAttribute('data-task-id'));
      this.$store.dispatch('updateTask', {task_statuses_id: to_status, id: task_id}).then(() => {
        this.taskGen();
      }).catch((e) => {
        alert(e.response.data);
      });
      return false;

    },

  },
  beforeDestroy() {
    this.$store.commit('setTasks', []);
    this.buildArraysForStatus([]);
  },


  mounted() {
    this.$store.dispatch('getTaskStatuses').then(() => {
      this.$store.commit('setTasks', this.tasks);
      this.buildArraysForStatus();
      if (this.preSelectedTaskID > 0) {
        this.selectedTaskId = this.preSelectedTaskID;
        this.taskDetailsDialog = true;
      }
    })


  }
}
</script>

<style scoped>
.status {
  background: #ffffff;
  margin-right: 10px;
  border-radius: 20px;

}

.add-task {
  cursor: pointer;
}

.hide-scroll {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  margin-top: 10px;
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}

.ghost{
  opacity: 0.5;
  border: 2px dashed dimgrey;
}
</style>
