<template>

  <v-list elevation="0" color="transparent" three-line>
    <div :key="i" v-for="(item, i) in comments">
      <v-sheet :class="{'is_not_service': !item.is_service}" style="background: white; border-radius: 10px; overflow: hidden" class="mt-5 pa-4"
                   :key="item.id"
      >
        <UserLinkComponent :text=" $moment.utc(item.created_at).local().fromNow()" :user="item.user"/>
        <v-sheet>
          <PostText style="padding: 0; margin-top: 10px" :text="item.comment"/>
          <a :href="item.file.link" target="_blank" v-if="item.file"
             style="display: inline-block; color: black; background: #a8e3ff; border-radius: 4px; font-size: 14px; padding: 4px;"
             v-html="item.file.filename"></a>


        </v-sheet>

      </v-sheet>
    </div>
  </v-list>
  <!--  <v-timeline-->
  <!--      dense-->
  <!--  >-->

  <!--    <v-timeline-item v-for="comment in comments" :key="comment.id"-->
  <!--                     color="teal lighten-3"-->
  <!--                     small-->
  <!--    >-->
  <!--      <template v-slot:icon>-->
  <!--        <v-avatar size="32">-->
  <!--          <v-img-->
  <!--              :src="comment.user.avatar"-->
  <!--          ></v-img>-->
  <!--        </v-avatar>-->
  <!--      </template>-->

  <!--      <v-row class="pt-1">-->
  <!--        <v-col>-->
  <!--          <strong>-->
  <!--            {{ $moment.utc(comment.created_at).local().format("DD.MM HH:mm") }}-->
  <!--          </strong>-->
  <!--          <div class="caption mb-2">-->
  <!--            {{ comment.comment }}-->
  <!--          </div>-->

  <!--        </v-col>-->
  <!--      </v-row>-->
  <!--    </v-timeline-item>-->
  <!--  </v-timeline>-->

</template>

<script>
import UserLinkComponent from "@/components/Profile/UserLinkComponent";
import PostText from "@/components/Feed/PostText";
export default {
  name: "CommentsTimeline",
  components: {PostText, UserLinkComponent},
  props: ['comments']
}
</script>

<style scoped>
.is_not_service {
  border: 1px solid #cdf3ff;
}
</style>
