<template>
  <CardUI class="task" :style="`${task.task_presets_id > 0 ? 'border: 1px solid #2196f3' : ''}`"
          @click.native="taskDetailsDialog = true; openTask();">
    <template v-slot:body>

      <span @click="$router.push('/projects/'+task.project.id)"
            v-if="context != 'project' && task && task.project.id > 0"
            class="project-badge v-chip--clickable"
            v-html="task.project.title.length<15?task.project.title:task.project.title.slice(0, 15)+'...'"></span>
      <p class="card_text" style="padding-right: 2em">{{ task.text }}</p>


      <div class="d-flex" style="justify-content: space-between">
        <v-avatar size="24">
          <v-img
              :src="task.assignee.avatar"
          ></v-img>
        </v-avatar>
        <span class="badge">
          до {{ $moment.utc(task.deadline).local().format("DD MMMM") }}
        </span>
      </div>

      <TaskDetailsDialog v-if="taskDetailsDialog" :context="context" :dialog="taskDetailsDialog" :task_id="task.id"
                         @close="taskDetailsDialog = false"/>
    </template>
  </CardUI>
</template>

<script>
import TaskDetailsDialog from "@/components/Tasks/TaskDetailsDialog.vue";
import analytics from "@/api/analytics.js";
import CardUI from "@/components/UI/CardUI";

export default {
  name: "Task",
  components: {CardUI, TaskDetailsDialog},
  mounted() {
    if (this.opened) {
      this.taskDetailsDialog = this.opened;
      analytics.openProjectTaskEvent(this.task.id, this.$route.params.project_id, this.$store.state.user.currentUser.id, 'path');
    }

  },
  methods: {
    openTask() {
      analytics.openProjectTaskEvent(this.task.id, this.$route.params.project_id, this.$store.state.user.currentUser.id, this.context);
    }
  },
  data() {
    return {
      taskDetailsDialog: false
    }
  },
  props: ['task', 'context', 'opened']
}
</script>

<style scoped>
.p {
  color: #004496;
}

.card_text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.project-badge {
  background: #a8e3ff;
  opacity: 0.8;
  border-radius: 5px;
  padding: 5px;
  font-size: 10px;
  margin: 0.2em;
}

.badge {
  background: var(--accent-color, #7ac94c);
  color: white;
  border-radius: 15px;
  padding: 5px 10px;
  font-size: 12px;

}

</style>
