<template>
  <div>
    <input ref="file" style="display: none" type="file" @change="uploadFile($event.target.files[0])"/>
    <v-textarea v-model="comment" append-icon="mdi-attachment" class="mb-0" height="70" label="Комментарий" outlined
                @click:append="attach">

    </v-textarea>
    <div class="d-flex justify-space-between">
      <v-chip v-if="attachment">
        <v-icon left>
          mdi-file
        </v-icon>
        {{ attachment.filename }}
      </v-chip>
      <v-btn :loading="loading" :disabled="comment.length === 0 && !attachment" color="primary" outlined small
             @click="addComment">Отправить
      </v-btn>
    </div>
  </div>
</template>

<script>
import files from "@/api/files.js";
import tasks from "@/api/tasks.js";

export default {
  name: "CommentField",
  props: ['task_id'],
  data() {
    return {
      comment: "",
      attachment: null,
      loading: false
    }
  },
  methods: {
    addComment() {
      this.loading = true;
      let comment = {comment: this.comment, id: this.task_id};
      if (this.attachment) comment.file_id = this.attachment.id;
      tasks.addComment(comment).then(() => {
        this.comment = "";
        this.attachment = null;
        this.$emit('saveComment');
        this.loading = false;
      });

    },
    uploadFile(file) {
      let fd = new FormData();
      this.attachment = {
        'filename': 'Загрузка...'
      }
      fd.append("file", file);
      files.store(fd).then((r) => {
        this.attachment = r.data;
      });
    },
    attach() {
      this.$refs.file.click();
    },
  }
}
</script>

<style scoped>
.files__file {
  background: #eaeaea;
  padding: 5px;
  border-radius: 10px;
  font-size: 10px;
  margin: 5px 10px;
}
</style>
