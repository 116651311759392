<template>
  <v-dialog
      v-model="open"
      max-width="1000"
  >
    <v-card color="#eaeaea" max-width="100%">
      <v-row style="max-width: 100%; margin: 0 !important;">
        <v-btn color="darken-1"
               icon
               style="position:absolute; right: 1em; top: 1em; z-index: 100"
               @click="$emit('close')"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
        <v-col col="12" lg="7" style="background: white;">
          <v-card-title v-if="task" class="headline">
            <div v-if="task.observers">
              <v-btn :loading="observeLoading"
                     v-if="task.observers.map(el => el.id).filter((el) => el == $store.state.user.currentUser.id).length ==  0"
                     icon
                     @click="observeTask">
                <v-icon>mdi-star</v-icon>
              </v-btn>
              <v-btn :loading="observeLoading" v-else color="orange" icon @click="unObserveTask">
                <v-icon>mdi-star</v-icon>
              </v-btn>
            </div>
            Задача #{{ task_id }}
            <v-spacer/>
            <div>
              <v-menu v-if="!readonly" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      color="primary"
                      outlined
                      dark
                      rounded
                      small
                      v-bind="attrs"
                      v-on="on"
                  >
                    Действия
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-title>Нет доступных действий</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-card-title>
          <v-card-text>
            <v-skeleton-loader v-if="loading"
                               type="heading, list-item-two-line, list-item-two-line, chip"
            ></v-skeleton-loader>
            <template v-else>
              <v-textarea :readonly="readonly"
                          v-model="task.text"
                          :error-messages="errors.text"
                          label="Задача"
                          outlined
                          placeholder="Давайте напишем здесь задачу, например Организовать встречу с заказчиком"
              ></v-textarea>
              <v-btn v-if="task.link_url" :href="task.link_url" outlined small color="primary" class="mb-4" rounded>Форма
                для заполнения
              </v-btn>
              <v-text-field :readonly="readonly" v-model="task.deadline" :error-messages="errors.deadline"
                            hint="Крайний срок исполнения задачи"
                            label="Дедлайн" outlined type="datetime-local"/>


              <v-autocomplete
                  :readonly="readonly"
                  :error-messages="errors.assignee_id"
                  :items="assignees"
                  v-model="task.assignee_id"
                  hide-no-data
                  hide-selected
                  item-text="name"
                  item-value="id"
                  label="Ответственный"
                  outlined
                  placeholder="Иван Иванов"
              >
                <template v-slot:selection="data">
                  <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="task.assignee_id = null"
                  >
                    <v-avatar left>
                      <v-img :src="data.item.avatar"></v-img>
                    </v-avatar>
                    {{ data.item.last_name }}
                    {{ data.item.first_name }}
                  </v-chip>
                </template>

              </v-autocomplete>

              <p>Наблюдатели задачи:</p>
              <UserLinkComponent v-for="observer in task.observers" :key="observer.id" :user="observer"/>
              <p class="mt-4">Постановщик задачи:</p>
              <UserLinkComponent :user="task.creator"/>
              <v-btn rounded
                     v-if="!readonly"
                     :loading="loading"
                     class="float-right"
                     color="green darken-1"
                     dark
                     elevation="0"
                     @click="create"
              >
                Сохранить
              </v-btn>
            </template>
          </v-card-text>


        </v-col>
        <v-col cols="12" lg="5" style="background: #fcfcfc">
          <v-card color="transparent" elevation="0">
            <h4 class="subtitle-1 mt-2 mb-2">Комментарии и события</h4>
            <CommentField v-if="task.id > 0 && !readonly" :task_id="task.id" @saveComment="getComments()"/>
            <v-skeleton-loader v-if="commentsLoading"
                               type="list-item-two-line,list-item-two-line,list-item-two-line"
            ></v-skeleton-loader>
            <div v-else style="height: 500px; overflow-y: scroll">
              <CommentsTimeline :comments="comments"></CommentsTimeline>
            </div>

          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import tasks from "@/api/tasks.js";
import CommentsTimeline from "@/components/Tasks/CommentsTimeline.vue";
import CommentField from "@/components/Tasks/CommentField.vue";
import projects from "@/api/projects.js";
import UserLinkComponent from "@/components/Profile/UserLinkComponent.vue";


export default {
  name: "TaskDetailsDialog",
  components: {CommentsTimeline, CommentField, UserLinkComponent},
  props: {
    task_id: {},
    dialog: {},
    context: {}
  },
  computed: {
    assignees() {
      if (this.task && this.task.project_id > 0) return this.participants;
      else return [];
    },
    readonly() {
      return this.task.readonly;
    }
  },
  watch: {
    "open": function () {
      if (!this.open) this.$emit('close');
    }
  },
  data() {
    return {
      file: null,
      task: {
        assignee_id: null,
        text: null,
        deadline: null,
        project_id: null,
      },
      participants: [],
      comments: [],
      loading: true,
      commentsLoading: true,
      observeLoading: false,
      errors: {},
      open: false
    }
  },
  methods: {
    observeTask() {
      this.observeLoading = true;
      tasks.observe({id: this.task_id}).then((r) => {
        this.task = r.data;
        this.observeLoading = false;
      });

    },
    unObserveTask() {
      this.observeLoading = true;
      tasks.unobserve({id: this.task_id}).then((r) => {
        this.task = r.data;
        this.observeLoading = false;
      });
    },

    create() {
      this.loading = true;
      this.$store.dispatch('updateTask', {
        ...this.task,
        deadline: this.$moment(this.task.deadline, "YYYY-MM-DDTHH:mm").utc()
      }).then(() => {
        this.$emit('close');
      }).catch((e) => {
        if (e.response.data) this.errors = e.response.data.errors;
        else alert("Возникла ошибка. Обратитесь к Антону Осипову" + JSON.stringify(e.message) + JSON.stringify(e.response.code))
      }).finally(() => {
        this.loading = false;
      })

    },
    getComments() {
      tasks.comments({id: this.task_id}).then((r) => {
        this.comments = r.data;
        this.commentsLoading = false;
      })
    }
  },
  mounted() {
    this.open = this.dialog;
    tasks.item({id: this.task_id}).then((r) => {
      this.task = r.data;
      this.loading = false;
      this.task.deadline = this.$moment.utc(this.task.deadline).local().format("YYYY-MM-DDTHH:mm");
      if (this.task.project_id > 0) {
        projects.participants({id: this.task.project_id}).then((r) => {
          this.participants = r.data;
        });
      }
    })
    this.getComments();
    this.errors = [];


  },
}
</script>

<style scoped>

</style>
