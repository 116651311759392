<template>
  <div>
    <v-dialog
        v-model="dialog"
        max-width="400"
        persistent
    >
      <v-card :loading="loading">
        <v-card-title class="headline">
          Создание задачи
        </v-card-title>
        <v-card-text>
          <v-textarea
              outlined
              v-model="task.text"
              :error-messages="errors.text"
              label="Задача"
              placeholder="Давайте напишем здесь задачу, например Организовать встречу с заказчиком"
          ></v-textarea>
          <v-text-field :error-messages="errors.deadline" hint="Крайний срок исполнения задачи" outlined
                        v-model="task.deadline" label="Дедлайн" type="datetime-local"></v-text-field>
          <v-autocomplete
              :error-messages="errors.assignee_id"
              v-model="task.assignee_id"
              hide-no-data
              :items="assignees"
              hide-selected
              item-text="name"
              item-value="id"
              label="Ответственный"
              placeholder="Иван Иванов"
              outlined
          >
            <template v-slot:selection="data">
              <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="task.assignee_id = null"
              >
                <v-avatar left>
                  <v-img :lazy-src="data.item.avatar_16" :src="data.item.avatar"></v-img>
                </v-avatar>
                {{ data.item.last_name }}
                {{ data.item.first_name }}
              </v-chip>
            </template>

          </v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="darken-1"
              text
              @click="$emit('close')"
          >
            Отменить
          </v-btn>
          <v-btn
              :loading="loading"
              color="green darken-1"
              text
              @click="create"
          >
            Создать
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: "TaskCreateDialog",
  props: {
    dialog: {},
    context: {}
  },
  computed: {
    assignees() {
      if (this.context === 'project') return this.$store.state.projects.currentProjectParticipants;
      else return [];
    }
  },
  data() {
    return {
      task: {
        assignee_id: null,
        text: null,
        deadline: null,
        project_id: null
      },
      loading: false,
      errors: {}
    }
  },
  methods: {
    create() {
      this.loading = true;
      this.$store.dispatch('storeTask', this.task).then(() => {
        this.$emit('close');
      }).catch((e) => {
        if (e.response.data) this.errors = e.response.data.errors;
        else alert("Возникла ошибка. Обратитесь к Антону Осипову" + JSON.stringify(e.message) + JSON.stringify(e.response.code))
      }).finally(() => {
        this.loading = false;
      })

    }
  },
  mounted() {
    this.task.deadline = this.$moment.utc().add(1, 'weeks').local().format("YYYY-MM-DDTHH:mm");
    if (this.context === 'project') {
      this.task.project_id = this.$store.state.projects.currentProject.id
    }
    this.errors = []
  },
}
</script>

<style scoped>

</style>
